<template>
  <v-dialog
    v-model="show"
    persistent
    :width="width"
    :eager="true"
    @click:outside="show = !show"
  >
    <v-card>
      <v-icon
        v-show="hiddenIcon"
        style="position:absolute;top: 20px;right: 20px"
        @click="$emit('close-modal')"
      >
        {{ icons.mdiClose }}
      </v-icon>
      <v-card-title
        class="justify-center"
      >
        <span v-if="dialog ==='add'">{{ header }}</span>
        <span v-else>{{ header }}</span>
        <slot
          name="header"
        >
        </slot>
      </v-card-title>
      <!-- <v-card-text>
        <slot
          name="subheader"
        >
        </slot>
      </v-card-text> -->
      <!-- <v-divider></v-divider> -->
      <v-card-text class="pa-4">
        <slot name="body"></slot>
      </v-card-text>
      <!-- <v-divider></v-divider> -->
      <v-card-actions class="justify-center">
        <slot
          name="footer"
        >
        </slot>
      </v-card-actions>
      <v-card-actions
        v-show="hiddenActions"
        class="justify-center"
        @click.stop="show=false"
      >
        <v-btn
          color="primary"
          large
          :dialog="dialog"
          :loading="isLoadingButton"
          :disabled="isDisabledButton"
          @click="$emit('handler-button')"
        >
          <span>{{ textButton }}</span>
        </v-btn>

        <v-btn
          color="primary"
          large
          outlined
          @click="$emit('close-button')"
        >
          Batal
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose } from '@mdi/js'

export default {
  name: 'ModalDialog',
  props: {
    visible: {
      type: Boolean,
      default: () => false,
    },
    outside: {
      type: Boolean,
      default: () => false,
    },
    width: {
      type: Number,
      default: () => 600,
    },
    isLoadingButton: {
      type: Boolean,
      default: () => false,
    },
    isDisabledButton: {
      type: Boolean,
      default: () => false,
    },
    dialog: {
      type: String,
      default: () => 'add',
    },
    header: {
      type: String,
      default: () => '',
    },
    hiddenActions: {
      type: Boolean,
      default: () => true,
    },
    hiddenIcon: {
      type: Boolean,
      default: () => true,
    },
    textButton: {
      type: String,
      default: () => '',
    },
    isHideButton: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      icons: {
        mdiClose,
      },
    }
  },
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      },
    },
  },
  // props: {
  //   header: {
  //     type: String,
  //     default() {
  //       return ''
  //     },
  //   },
  //   body: {
  //     type: Object,
  //     default() {
  //       return {}
  //     },
  //   },
  //   footer: {
  //     type: Object,
  //     default() {
  //       return {}
  //     },
  //   },
  // },
  // methods: {
  //   close() {
  //     this.$emit('close')
  //   },
  // },
}
</script>

<style>
</style>
