<template>
  <div>
    <router-link
      to="/forum"
      class="text-decoration-none text-subtitle-2 font-weight-bold"
    >
      <v-icon
        color="primary"
        dense
        class="mr-2"
      >
        {{ icons.mdiArrowLeft }}
      </v-icon>
      Kembali
    </router-link>
    <v-card class="mt-8">
      <v-img
        v-if="forum.image === null"
        :src="forum.image"
      >
      </v-img>
      <v-img
        :src="forum.image"
      >
      </v-img>

      <v-card-text class="pa-8">
        <p class="mb-4">
          {{ getFormatDate(forum.created_at) }}
        </p>
        <h2 class="mb-4">
        </h2>
        <div>
          <v-row class="mb-6">
            <v-col
              md="4"
              cols="12"
            >
              <div class="d-flex align-center">
                <v-avatar size="30">
                  <v-img src="@/assets/images/avatars/1.png"></v-img>
                </v-avatar>
                <span class="ms-2 text-forum">{{ forum.users.name }}</span>
              </div>
            </v-col>
            <v-col
              md="2"
              sm="4"
              cols="6"
              class="pr-0 pb-0"
            >
              <div class="d-flex">
                <span class="mb-4">
                  <img
                    v-if="postLikes.includes(forum.uuid)"
                    src="@/assets/icons/color/like-thick.svg"
                    width="22"
                    class="pointer"
                    @click="addLike(forum.uuid)"
                  >
                  <img
                    v-else
                    src="@/assets/icons/like.svg"
                    width="22"
                    alt=""
                    class="pointer"
                    @click="addLike(forum.uuid)"
                  >
                </span>
                <span class="ms-2">{{ forum.like_count === null ? 0 : forum.like_count }} Suka</span>
              </div>
            </v-col>
            <v-col
              md="2"
              sm="4"
              cols="6"
              class="px-0 pb-0"
            >
              <div class="d-flex">
                <span class="mb-4">
                  <v-img
                    width="20"
                    src="@/assets/icons/messages.svg"
                  ></v-img>
                </span>
                <span class="ms-2">{{ forum.comment_count === null ? 0 : forum.comment_count }} Komentar</span>
              </div>
            </v-col>
            <v-col
              md="2"
              sm="4"
              cols="6"
              class="pr-0 pb-0"
            >
              <div class="d-flex">
                <span class="mb-4">
                  <img
                    v-if="postBookmark.includes(forum.uuid)"
                    src="@/assets/icons/archive-tick.svg"
                    width="22"
                    alt=""
                    class="cursor-pointer"
                    @click="addBookmark(forum.uuid)"
                  >

                  <img
                    v-else
                    src="@/assets/icons/archive-add.svg"
                    width="22"
                    alt=""
                    class="cursor-pointer"
                    @click="addBookmark(forum.uuid)"
                  >
                </span>
                <span class="ms-2">Bookmark</span>
              </div>
            </v-col>
            <v-col
              md="2"
              sm="12"
              cols="6"
              class="px-md-0 pb-0"
            >
              <div class="d-flex">
                <span class="mb-4 font-weight-bold">
                  Topik:
                </span>
                <span class="ms-2">{{ forum.topic.title }}</span>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </div>
        <div class="my-8">
          <div class="mb-9">
            <p v-html="content">
            </p>
            <div>
              <v-btn
                color="primary"
                class="me-2"
                @click="editPost()"
              >
                Edit
              </v-btn>
              <v-btn
                color="primary"
                outlined
                @click="confirmPostDestroy(forum.uuid)"
              >
                Hapus
              </v-btn>
            </div>
          </div>
          <v-divider></v-divider>
        </div>
        <div>
          <h2 class="mb-10">
            Komentar .<span class="text-body-2"> {{ forum.comment_count === null ? 0 : forum.comment_count }} Komentar</span>
          </h2>
          <v-row>
            <v-col md="1">
              <v-avatar>
                <v-img src="@/assets/images/avatars/1.png"></v-img>
              </v-avatar>
            </v-col>
            <v-col
              cols="11"
              class="mb-10"
            >
              <h4 class="mb-4">
                Tambahkan Komentar
              </h4>
              <v-text-field
                v-model="comment.content"
                label="Tulis Komentar"
                dense
                class="mb-2"
              ></v-text-field>
              <v-btn
                class="me-2"
                color="primary"
                :loading="isLoadingButton"
                :disabled="isLoadingButton"
                @click="addComment()"
              >
                Kirim
              </v-btn>
              <v-btn
                color="primary"
                outlined
              >
                Batal
              </v-btn>
            </v-col>
          </v-row>
          <v-row
            v-for="commentUser in forum.comment"
            :key="commentUser.uuid"
          >
            <div
              class="position-absolute"
              style="right: 15px"
            >
              <v-spacer></v-spacer>
              <v-menu
                v-if="commentUser.user.uuid === user_uuid || isModerator"
                offset-y
                nudge-top="25"
                nudge-left="125"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="black"
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon size="20">
                      {{ icons.mdiDotsVertical }}
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <a
                    v-if="commentUser.user.uuid === user_uuid || isModerator"
                    @click="setAction(1, commentUser.uuid)"
                  >
                    <v-list-item>
                      <v-list-item-title>
                        <small
                          class="ml-2"
                        > Hapus Komentar</small>
                      </v-list-item-title>
                    </v-list-item>
                  </a>
                  <a
                    v-if="isModerator"
                    @click="setAction(2, commentUser.user.uuid)"
                  >
                    <v-list-item>
                      <v-list-item-title>
                        <small
                          class="ml-2"
                        > Mute Pengguna</small>
                      </v-list-item-title>
                    </v-list-item>
                  </a>
                  <a
                    v-if="isModerator"
                    @click="setAction(3,commentUser.user.uuid)"
                  >
                    <v-list-item>
                      <v-list-item-title>
                        <small
                          class="ml-2 text-suspend"
                        > Suspend Pengguna</small>
                      </v-list-item-title>
                    </v-list-item>
                  </a>
                </v-list>
              </v-menu>
            </div>
            <v-col md="1">
              <v-avatar>
                <v-img src="@/assets/images/avatars/1.png"></v-img>
              </v-avatar>
            </v-col>
            <v-col md="11">
              <span class="text-subtitle-2">
                {{ commentUser.user.name }}
              </span>
              <span class="d-block text-subtitle-1 font-weight-medium">
                {{ commentUser.content }}
              </span>
              <small>1 jam yang lalu</small>
              <div class="mb-4">
                <div class="d-flex justify-start align-center my-4">
                  <div
                    class="pr-4 cursor-pointer"
                    @click="showReplyComment(commentUser.uuid)"
                  >
                    <span class="text-subtitle-2">{{ commentUser.sub_comment.length }} Komentar</span>
                  </div>
                  <hr
                    role="separator"
                    aria-orientation="vertical"
                    class="v-divider v-divider--vertical theme--light"
                  >
                  <div
                    class="px-4"
                    style="cursor:pointer;"
                    @click="replyShow(commentUser.uuid)"
                  >
                    <span class="text-subtitle-2 d-inline-block primary--text pr-1">
                      Balas Komentar
                    </span>
                    <v-icon
                      color="primary"
                      class="d-inline-block replyToggle"
                      :class="{ 'rotate': comment_uuid == commentUser.uuid ? true : false }"
                      small
                    >
                      {{ icons.mdiChevronDown }}
                    </v-icon>
                  </div>
                </div>
                <div class="reply-section">
                  <v-row v-show="comment_uuid == commentUser.uuid ? true : false">
                    <v-col md="1">
                      <v-avatar>
                        <v-img src="@/assets/images/avatars/1.png"></v-img>
                      </v-avatar>
                    </v-col>
                    <v-col
                      cols="11"
                      class="mb-10"
                    >
                      <v-text-field
                        v-model="replyComment"
                        label="Tulis Komentar"
                        dense
                        class="mb-2"
                      ></v-text-field>
                      <v-btn
                        class="me-2"
                        color="primary"
                        :loading="isLoadingButton"
                        :disabled="isLoadingButton"
                        @click="addComment()"
                      >
                        Kirim
                      </v-btn>
                      <v-btn
                        color="primary"
                        outlined
                        @click="comment_uuid = ''"
                      >
                        Batal
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row
                    v-for="commentReply in commentUser.sub_comment"
                    v-show="replyCommentUuid == commentUser.uuid ? true : false"
                    :key="commentReply.uuid"
                    class="mt-0"
                  >
                    <div
                      class="position-absolute"
                      style="right: 15px"
                    >
                      <v-spacer></v-spacer>
                      <v-menu
                        v-if="commentReply.user.uuid === user_uuid || isModerator"
                        offset-y
                        nudge-top="25"
                        nudge-left="125"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="black"
                            icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon size="20">
                              {{ icons.mdiDotsVertical }}
                            </v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <a
                            v-if="commentReply.user.uuid === user_uuid || isModerator"
                            @click="setAction(1, commentReply.uuid)"
                          >
                            <v-list-item>
                              <v-list-item-title>
                                <small
                                  class="ml-2 text-suspend"
                                > Hapus Komentar</small>
                              </v-list-item-title>
                            </v-list-item>
                          </a>
                          <a
                            v-if="isModerator"
                            @click="setAction(2, commentReply.user.uuid)"
                          >
                            <v-list-item>
                              <v-list-item-title>
                                <small
                                  class="ml-2 text-suspend"
                                > Mute Pengguna</small>
                              </v-list-item-title>
                            </v-list-item>
                          </a>
                          <a
                            v-if="isModerator"
                            @click="setAction(3, commentReply.user.uuid)"
                          >
                            <v-list-item>
                              <v-list-item-title>
                                <small
                                  class="ml-2 text-suspend"
                                > Suspend Pengguna</small>
                              </v-list-item-title>
                            </v-list-item>
                          </a>
                        </v-list>
                      </v-menu>
                    </div>
                    <v-col
                      md="1"
                      class="py-2"
                    >
                      <v-avatar>
                        <v-img src="@/assets/images/avatars/1.png"></v-img>
                      </v-avatar>
                    </v-col>
                    <v-col
                      md="11"
                      class="py-2"
                    >
                      <span class="text-subtitle-2">
                        {{ commentReply.user.name }}
                      </span>
                      <span class="d-block text-subtitle-1 font-weight-medium">
                        {{ commentReply.content }}
                      </span>
                      <small class="d-block mb-5">1 jam yang lalu</small>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
    <ModalDialog
      :visible="modalDialog"
      :width="width"
      :is-loading-button="isLoadingButton"
      header="Edit Post"
      text-button="Update"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
      @handler-button="updatePost"
    >
      <template v-slot:body>
        <div>
          <v-autocomplete
            v-model="postEdit.topic"
            :items="categories"
            label="Topik"
            return-object
            item-text="title"
            item-value="uuid"
            dense
            outlined
            open-on-clear
          ></v-autocomplete>
        </div>
        <div>
          <v-text-field
            v-model="postEdit.title"
            label="Judul Post"
            dense
            outlined
          >
          </v-text-field>
        </div>
        <div class="mb-4">
          <FileInput
            v-model="initialFile"
            label="Gambar"
            :value="postEdit.image"
            outlined
            dense
            :disabled="false"
            :prependicon="icons.mdiImageMultipleOutline"
            @input="getFile"
          >
          </FileInput>
        </div>
        <div>
          <CKEditor
            v-model="initialContent"
            label="Content"
            outlined
            dense
            @input="handleCKeditor($event)"
          ></CKEditor>
        </div>
        <div>
          <h3 class="mb-4">
            Status Forum
          </h3>
          <div class="d-flex">
            <div class="me-4">
              <v-switch
                v-model="postEdit.is_private"
              >
              </v-switch>
            </div>
            <div>
              <h5>Private</h5>
              <span>(Hanya anda yang bisa melihat forum)</span>
            </div>
          </div>
        </div>
      </template>
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmPostDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Post"
      @destroy-button="deletePost"
      @close-modal="confirmPostDialog = false"
      @close-button="confirmPostDialog = false"
    >
      <template v-slot:body>
        Apakah anda yakin ingin menghapus post “{{ forum.title }}” ?
      </template>
    </ConfirmDialog>
    <ConfirmDialog
      v-for="(confirm,index) in confirms"
      :key="index"
      :visible="confirmActionDialog == confirm.id ? true : false"
      :header="confirm.type ? confirm.title : ''"
      :is-loading-button="isLoadingButton"
      @destroy-button="setStatusUser(confirm.id)"
      @close-button="confirmActionDialog = false"
    >
      <template v-slot:body>
        <p> Apakah anda yakin ingin {{ confirm.title }}  ?</p>
        <div class="mt-5">
          <v-text-field
            v-if="confirm.id === 2 || confirm.id === 3"
            v-model="reason.title"
            label="Alasan"
            dense
            outlined
          >
          </v-text-field>
        </div>
        <div>
          <v-menu
            v-model="deadline"
            :close-on-content-click="false"
            nudge-right="0"
            nudge-top="20"
            max-width="100%"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="reason.end_date"
                label="Jangka Waktu"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="reason.end_date"
              :first-day-of-week="0"
              locale="id-id"
              color="primary"
              @input="deadline = false"
            ></v-date-picker>
          </v-menu>
        </div>
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiArrowLeft, mdiChevronDown, mdiDotsVertical, mdiImageMultipleOutline } from '@mdi/js'
import CKEditor from '../../components/CkEditor.vue'
import ConfirmDialog from '../../components/ConfirmDialog.vue'
import FileInput from '../../components/FileInput.vue'
import ModalDialog from '../../components/ModalDialog.vue'

export default {
  name: 'ForumDetail',
  components: {
    ModalDialog,
    ConfirmDialog,
    FileInput,
    CKEditor,
  },
  data() {
    return {
      forum: {
        users: {},
        topic: {},
        comment: [],
      },
      deadline: false,
      comment: {
        parent_comment_uuid: null,
        forum_uuid: null,
        content: '',
      },
      reason: {
        title: '',
        end_date: '',
      },
      postBookmark: [],
      bookmarks: [],
      bookmark: {
        users_uuid: null,
        forum_uuid: null,
      },
      like: {
        users_uuid: null,
        forum_uuid: null,
      },
      likes: [],
      postLikes: [],
      width: 600,
      actionUserUuid: null,
      isModerator: false,
      confirmActionDialog: null,
      user_uuid: null,
      postEdit: { content: null },
      topic: null,
      categories: [],
      school: {},
      school_uuid: null,
      post_uuid: null,
      isLoadingButton: false,
      replyComment: '',
      replyCommentUuid: null,
      comment_uuid: null,
      isReplyShow: false,
      isReplySectionShow: false,
      icons: {
        mdiImageMultipleOutline,
        mdiDotsVertical,
        mdiArrowLeft,
        mdiChevronDown,
      },
      modalDialog: false,
      confirmPostDialog: false,
      deleteConfirmDialog: false,
      muteConfirmDialog: false,
      suspendConfirmDialog: false,
      moderator: {},
      confirms: [
        {
          id: 1,
          title: 'Hapus Komentar',
          type: 'deleteConfirmDialog',
          visible: false,
        },
        {
          id: 2,
          title: 'Mute Pengguna',
          type: 'muteConfirmDialog',
          visible: false,
        },
        {
          id: 3,
          title: 'Suspend Pengguna',
          type: 'suspendConfirmDialog',
          visible: false,
        },
      ],
      tempContent: '',
      tempImage: [],
      content: '',
    }
  },
  computed: {
    initialContent: {
      get() {
        if (this.postEdit.content === null) {
          return ''
        }

        return this.postEdit.content
      },
      set() {
        return this.postEdit.content
      },
    },
    initialFile: {
      get() {
        if (typeof this.postEdit.image === 'string') {
          return new File([this.postEdit.image], this.postEdit.image, { type: 'text/plain' })
        }

        return this.postEdit.image
      },
      set() {
        return this.postEdit.image
      },
    },
  },
  mounted() {
    this.getSchoolUuid()
    this.getCategories()
    this.getForum(this.$route.params.uuid)
    this.getLike()
    this.getBookmark()
    this.getModerator()
  },
  methods: {
    async deleteStorage(fileName) {
      await this.$services.ApiServices.deleteStorage('news', fileName).then(({ data }) => true)
    },
    handleCKeditor(event, index) {
      this.postEdit.content = event
      if (this.tempContent.length < event.length) {
        this.tempContent = event
      }
    },
    async getForum(uuid) {
      await this.$services.ApiServices.get('forum', uuid).then(
        ({ data }) => {
          this.forum = data.data
          this.content = data.data.content
          this.postEdit = data.data
          const image = data.data.content.split('"')
          image.forEach(async item => {
            const img = item.split('/').pop()
            if (img.length === 44) {
              this.tempImage.push(img)
            }
          })
        },
        err => console.error(err),
      )
    },
    async updatePost() {
      this.isLoadingButton = true

      this.formData = new FormData()
      this.formData.append('topic_uuid', this.postEdit.topic.uuid)
      this.formData.append('user_id', this.user_uuid)
      this.formData.append('content', this.postEdit.content)
      this.formData.append('title', this.postEdit.title)
      this.formData.append('image', this.postEdit.image)
      if (typeof this.postEdit.image === 'string') {
        this.formData.delete('image')
      }
      const image = this.postEdit.content.split('"')
      image.forEach(item => {
        const img = item.split('/').pop()
        if (img.length === 44) {
          const carIndex = this.tempImage.indexOf(img)
          this.tempImage.splice(carIndex, 1)

          this.tempImage.forEach(async item => {
            await this.deleteStorage({ file: item })
          })
        }
      })
      await this.$services.ApiServices.update('forum', this.formData, this.$route.params.uuid).then(
        ({ data }) => {
          this.forum = data.data
          this.showSnackbar({
            text: 'Update Post to post successfully',
            color: 'success',
          })
          this.isLoadingButton = false
        },
        err => {
          this.isLoadingButton = false
        },
      )
      this.modalDialog = false
      await this.getForum(this.$route.params.uuid)
    },
    async addComment() {
      this.isLoadingButton = true

      if (this.comment_uuid !== null) {
        this.comment.parent_comment_uuid = this.comment_uuid
      }
      if (this.replyComment !== '') {
        this.comment.content = this.replyComment
      }
      this.comment.forum_uuid = this.$route.params.uuid
      await this.$services.ApiServices.add('commentforum', this.comment).then(
        ({ data }) => {
          this.showSnackbar({
            text: 'Add Comment to post successfully',
            color: 'success',
          })
          this.comment = {}
          this.replyComment = ''
          this.isLoadingButton = false
        },
        err => {
          this.isLoadingButton = false
        },
      )
      await this.getForum(this.$route.params.uuid)
    },
    async getModerator() {
      console.log(this.user_uuid)
      await this.$services.ApiServices.get('employee', this.user_uuid).then(
        ({ data }) => {
          this.moderator = data.data
        },
        err => console.error(err),
      )
    },
    async deletePost() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy('forum', this.forum.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: 'Delete post successfully',
            color: 'error',
          })
        },
        err => {
          this.isLoadingButton = false
        },
      )
      this.isLoadingButton = false
      this.confirmPostDialog = false

      return this.$router.back()
    },
    async getCategories() {
      await this.$services.ApiServices.list('forumtopic', { school_uuid: this.school_uuid }).then(
        ({ data }) => {
          this.categories = data.data
        },
        err => console.error(err),
      )
    },
    async setStatusUser(id) {
      this.isLoadingButton = true
      const dataViolation = {
        moderator_uuid: this.moderator.uuid,
        user_uuid: this.actionUserUuid,
        type: '',
        end_date: this.reason.end_date,
        reason: this.reason.title,
      }
      if (id === 2) {
        dataViolation.type = 'muted'
      }
      if (id === 3) {
        dataViolation.type = 'banned'
      }
      if (id === 1) {
        await this.$services.ApiServices.destroy('commentforum', this.actionUserUuid).then(
          ({ data }) => {
            this.showSnackbar({
              text: 'Delete comment successfully',
              color: 'error',
            })
            this.isLoadingButton = false
            this.confirmActionDialog = null
          },
          err => {
            this.isLoadingButton = false
          },
        )
        await this.getForum(this.$route.params.uuid)

        return
      }
      await this.$services.ApiServices.add('violationforum', dataViolation).then(
        ({ data }) => {
          this.showSnackbar({
            text: `${dataViolation.type} user successfully`,
            color: 'success',
          })
          this.isLoadingButton = false
          this.confirmActionDialog = null
        },
        err => {
          this.isLoadingButton = false
        },
      )

      await this.getForum(this.$route.params.uuid)
    },
    async addLike(uuid) {
      this.like.users_uuid = this.user_uuid
      this.like.forum_uuid = uuid
      if (!this.postLikes.includes(uuid)) {
        this.postLikes.push(uuid)
      } else {
        const index = this.postLikes.indexOf(uuid)

        this.postLikes.splice(index, 1)
      }
      await this.$services.ApiServices.add('likeforum', this.like).then(
        async ({ data }) => {
          // this.postLikes = await this.postLikes.filter(item => item !== uuid)
        },
        err => console.error(err),
      )
      // await this.getLike()
      await this.getForum(this.$route.params.uuid)
    },
    async getLike() {
      await this.$services.ApiServices.get('likeforum', this.user_uuid).then(
        ({ data }) => {
          this.likes = data.data
          this.likes.forEach(item => this.postLikes.push(item.uuid))
        },
        err => console.error(err),
      )
    },
    async getBookmark() {
      await this.$services.ApiServices.get('bookmark', this.user_uuid).then(
        ({ data }) => {
          this.bookmarks = data.data
          this.bookmarks.forEach(item => this.postBookmark.push(item.uuid))
        },
        err => console.error(err),
      )
    },
    async addBookmark(uuid) {
      this.bookmark.users_uuid = this.user_uuid
      this.bookmark.forum_uuid = uuid
      if (!this.postBookmark.includes(uuid)) {
        this.postBookmark.push(uuid)
      } else {
        const index = this.postBookmark.indexOf(uuid)

        this.postBookmark.splice(index, 1)
      }
      await this.$services.ApiServices.add('bookmark', this.bookmark).then(
        ({ data }) => {
          // this.postBookmark = this.postBookmark.filter(item => item !== uuid)
        },
        err => console.error(err),
      )
      // await this.getBookmark()
      await this.getForum(this.$route.params.uuid)
    },
    setAction(id, userUuid) {
      this.reason = {}
      this.confirmActionDialog = id
      this.actionUserUuid = userUuid
    },
    getFile(data) {
      this.postEdit.image = data
    },
    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        if (el.school === undefined) {
          this.school_uuid = el.uuid
          this.user_uuid = JSON.parse(localStorage.getItem('user')).user.uuid
          this.isModerator = el.is_moderator

          return
        }
        if (el.school === null) {
          this.showSnackbar({
            text: 'you dont have access to school forum',
            color: 'error',
          })

          return
        }
        this.user_uuid = JSON.parse(localStorage.getItem('user')).user.uuid
        this.school_uuid = el.school.uuid
        this.isModerator = el.is_moderator
      })

      return true
    },
    replyShow(uuid) {
      if (this.comment_uuid === uuid) {
        this.comment_uuid = null

        return
      }
      this.comment_uuid = uuid
    },
    showReplyComment(uuid) {
      if (this.replyCommentUuid === uuid) {
        this.replyCommentUuid = null

        return
      }
      this.replyCommentUuid = uuid
    },
    editPost() {
      this.modalDialog = true
      this.topic = this.postEdit.topic.uuid
    },
    confirmPostDestroy(uuid) {
      this.confirmPostDialog = true
    },
    getFormatDate(date) {
      const createdDate = new Date(date)
      const day = `0${createdDate.getDate()}`
      const month = `0${createdDate.getMonth() + 1}` // months are zero based
      const year = createdDate.getFullYear()

      const time = createdDate.toLocaleTimeString().replace(/(.*)\D\d+/, '$1')

      return `${time}, ${year}/${month.slice(-2)}/${day.slice(-2)}`
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style>
.text-suspend {
  color: #e30000 !important;
}

.replyToggle {
  transition: transform 0.3s ease-in-out !important;
}

.replyToggle.rotate {
  transform: rotate(180deg);
}
.pointer {
  cursor: pointer;
}
/* .v-application.theme--light .v-expansion-panel::before,
.v-expansion-panel::before {
  box-shadow: none !important;
}

.v-expansion-panels {
  display: grid !important;
  justify-content: start !important;
} */
</style>
